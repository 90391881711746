import React, {
  useCallback,
  useRef,
  // useEffect,
  // useState
} from 'react';
import { capFirstAll } from 'jsstring';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import ToggleSwitch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';

import NColumnGrid from '@indot/ncolumngrid';
// import NColumnGrid from './NColumnGrid';
import requestNonInteractiveCallback from '@jasmith79/request-noninteractive-callback';
import { extractSyntheticEventValue } from '@jasmith79/react-utils';
// import { usePartialState } from '@indot/state-hooks';
import { usePartialState } from '../hooks/state-hooks/index';

// import useQueryStringState from '../hooks/usequerystringdata';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
    minWidth: '200px',
  },
  form: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  },
});

const CRASH_TYPES = [
  { label: 'all', value: 'crashes' },
  { label: 'personal injury', value: 'injuries' },
  { label: 'incapacitating' },
  { label: 'fatal' },
];

const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const MONTHS_REVERSE_MAP = MONTHS.reduce((acc, month, i) => {
  acc[`${i + 1}`] = month;
  return acc;
}, {});

const toMenuIItem = id => ({ label, value }, i) => {
  const val = value === undefined ? label : value;
  let display = label;
  try {
    display = capFirstAll(label);
  } catch (err) {
    // pass
  }

  return <MenuItem value={val} key={`${id}_${label}`}>{display}</MenuItem>;
};

let lastFetched = '';

export default ({
  data,
  setData,
  districts,
  routes,
  getData,
}) => {
  const idleScheduler = useRef();
  const formRef = useRef(null);
  const setter = useCallback((key) => (evt) => {
    const value = extractSyntheticEventValue(evt);
    setData({ [key]: value });
  }, [setData]);

  const dateSetter = useCallback((key, dt) => {
    setData({ [key]: dt.toISO() });
  }, [setData]);

  const getter = () => {
    if (JSON.stringify(data) !== lastFetched) {
      lastFetched = JSON.stringify(data);
      getData(data);
    }
  };

  const classes = useStyles();
  const [toggleState, setToggleState] = usePartialState(data, setData)('view');

  const toggle = useCallback(
    () => setToggleState(toggleState === 'table' ? 'map' : 'table'),
    [toggleState, setToggleState],
  );

  const rts = routes;
  const dstrcts = districts.map(({ label, ...rest }) => ({
    label: label.toLowerCase(),
    ...rest,
  }));

  if (formRef.current && !idleScheduler.current) {
    idleScheduler.current = requestNonInteractiveCallback({
      element: formRef.current,
      notifyOnMouseLeave: true,
      f: getter,
    });
  }

  const toggleLabel = `Switch to ${toggleState === 'map' ? 'Table' : 'Map'} View`;

  return (
    <form
      onSubmit={getter}
      ref={formRef}
      id='crashmap-controls'
      className={classes.form}
    >
      <NColumnGrid columns={1}>
        <FormControlLabel
          control={
            <ToggleSwitch 
              onClick={toggle}
              checked={toggleState === 'map'}
              // labelId='crashmap_toggle_view'
            />
          }
          label={toggleLabel}
        />
        <FormControl className={classes.formControl}>
          <InputLabel id='crashmap_crash_type_select'>Crash Type</InputLabel>
          <Select
            labelId='crashmap_crash_type_select'
            value={data.crashType}
            onChange={setter('crashType')}
          >
            {CRASH_TYPES.map(toMenuIItem('crashmap_crash_type_select'))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id='crashmap_district_select'>Districts</InputLabel>
          <Select
            labelId='crashmap_district_select'
            value={data.district}
            onChange={setter('district')}
          >
            {[{ label: 'all' }, ...dstrcts].map(toMenuIItem('crashmap_district_select'))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id='crashmap_route_select'>Routes</InputLabel>
          <Select
            labelId='crashmap_route_select'
            value={data.route}
            onChange={setter('route')}
          >
            {[{ label: 'all' }, ...rts].map(toMenuIItem('crashmap_route_select'))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id='crashmap_month_select'>Months</InputLabel>
          <Select 
            multiple 
            labelId='crashmap_month_select'
            value={data.months}
            onChange={setter('months')}
            renderValue={(val) => val.map(x => capFirstAll(MONTHS_REVERSE_MAP[x])).join(',')}
          >
            {MONTHS.map((label, i) => toMenuIItem('crashmap_month_select')({ label, value: i + 1 }))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <DatePicker
            value={new Date(data.startDate)}
            onChange={(evt) => dateSetter('startDate', evt)}
            margin='normal'
            id='crashmap_start_date'
            label='Start Date'
            format='MM/dd/yyyy'
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <DatePicker
            value={new Date(data.endDate)}
            onChange={(evt) => dateSetter('endDate', evt)}
            margin='normal'
            id='crashmap_end_date'
            label='End Date'
            format='MM/dd/yyyy'
          />
        </FormControl>
      </NColumnGrid>
    </form>
  );
};

