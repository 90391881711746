import theme from '@indot/material-theme';

export const THREE_DAYS = 1000 * 60 * 60 * 24 * 3;
export const MAX_DATA_AGE_EPSILON = process?.env?.REACT_APP_DATA_AGE_EPSILON
  || THREE_DAYS;

export const GREEN = '#33CC64';
export const RED = '#FF3373';
export const ORANGE = '#FFA500';
export const YELLOW = theme.palette.secondary.light; // INDOT Gold

export const APP_TITLE = "INDOT Crash Statistics"
export const DRAWER_WIDTH = 350;
export const NOW = new Date();
export const FIRST_OF_THIS_MONTH = new Date(NOW.getFullYear(), NOW.getMonth(), 1);
export const FIRST_OF_LAST_MONTH = new Date(NOW.getFullYear(), NOW.getMonth() - 1, 1);
export const [FISCAL_START, FISCAL_END] = ((mon, yr) => {
  const julyThisYear = new Date(yr, 6);
  const julyLastYear = new Date(yr - 1, 6);
  const julyNextYear = new Date(yr + 1, 6);
  return mon > 6
    ? [julyThisYear, julyNextYear]
    : [julyLastYear, julyThisYear];
})(NOW.getMonth(), NOW.getFullYear());

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DEFAULT_ROUTES = [
  {
    label: 'I-265',
    value: 1265
  }, {
    label: 'I-275',
    value: 1275
  }, {
    label: 'I-465',
    value: 1465
  }, {
    label: 'I-469',
    value: 1469
  }, {
    label: 'I-64',
    value: 1064
  }, {
    label: 'I-65',
    value: 1065
  }, {
    label: 'I-69',
    value: 1069
  }, {
    label: 'I-70',
    value: 1070
  }, {
    label: 'I-74',
    value: 1074
  }, {
    label: 'I-80',
    value: 1080
  }, {
    label: 'I-865',
    value: 1865
  }, {
    label: 'I-94',
    value: 109
  },
];

export const DEFAULT_DISTRICTS = [
  {
    value: 1,
    label: 'CRAWFORDSVILLE',
    lat: 39.9614487984991,
    lon: -86.9773424022034,
  }, {
    value: 2,
    label: 'FORT WAYNE',
    lat: 41.1159017897887,
    lon: -85.4536486032707,
  }, {
    value: 3,
    label: 'GREENFIELD',
    lat: 40.0028272053755,
    lon: -85.5453666812278,
  }, {
    value: 4,
    label: 'LAPORTE',
    lat: 41.1856585501894,
    lon: -86.7769766164151,
  }, {
    value: 5,
    label: 'SEYMOUR',
    lat: 38.9658999624834,
    lon: -85.7963432441446,
  }, {
    value: 6,
    label: 'VINCENNES',
    lat: 38.4836826289981,
    lon: -87.0899332929082,
  }
];

export const DISTRICT_ZOOMS = {
  '1': 10,
  '2': 10,
  '3': 10,
  '4': 10,
  '5': 10,
  '6': 10,
};

export const CONSTRUCTION_MONTHS = [
  7,
  8,
  9,
  10,
  11,
  4,
  5,
  6,
];

export const CONSTRUCTION_MONTH_NAMES = CONSTRUCTION_MONTHS.map(i => MONTHS[i - 1]);


