import React, { useState } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import TMCSnackbar from '@indot/snackbar';

// import AuthOnlyRoute from '@indot/authonly';
import AuthOnlyRoute from './AuthOnly';
import { useUser } from '@indot/usetmcuser';

import LoginRoute from './routes/LoginRoute';
import HomeRoute from './routes/HomeRoute';
import AnswersRoute from './routes/AnswersRoute';
import SettingsRoute from './routes/SettingsRoute';

import usePreferences from '../hooks/usePreferences';
import { QSCtxProvider } from '../hooks/usequerystringstate';

import {
  CONSTRUCTION_MONTHS,
  FIRST_OF_THIS_MONTH,
  FISCAL_START,
} from '../constants';

const DEFAULT_SNACK = {
  message: '',
  variant: 'info',
};

const DEFAULT_DATA = {
  crashType: 'crashes',
  district: 'all',
  route: 'all',
  // startDate: FISCAL_START.toISOString(),
  startDate: FISCAL_START.toISOString(),
  endDate: FIRST_OF_THIS_MONTH.toISOString(),

  // defaults to construction months
  months: [...CONSTRUCTION_MONTHS].sort().map(String),
  view: 'map',
  zoom: 11,
  latitude: 0,
  longitude: 0,
};

function App() {
  const user = useUser();
  const [snackProps, setSnack] = useState(DEFAULT_SNACK);
  const { defaultCenter: [latitude, longitude], defaultZoom: zoom } = usePreferences();
  console.log(latitude, longitude);
  console.log(zoom);

  const setSnackState = (state) => {
    if (state.message === '' || state.message !== snackProps.message) {
      setSnack({
        ...DEFAULT_SNACK,
        ...state,
      });
    }
  };

  const closeSnack = () => setSnack({ message: '' });
  return (
    <div className="App">
      <Router>
        <QSCtxProvider initalData={{ ...DEFAULT_DATA, latitude, longitude, zoom }}>
          <Switch>
            <Route path="/login">
              {({ location }) => <LoginRoute location={location} notify={setSnackState} />}
            </Route>
            <Route exact path="/indotanswers">
              <AnswersRoute />
            </Route>
            <AuthOnlyRoute path="/settings" user={user}>
              <SettingsRoute />
            </AuthOnlyRoute>
            <AuthOnlyRoute path={["/", "/?*"]} user={user}>
              <HomeRoute />
            </AuthOnlyRoute>
          </Switch>
        </QSCtxProvider>
      </Router>
      <TMCSnackbar
        onClose={closeSnack}
        {...snackProps}
      />
    </div>
  );
}

export default App;
