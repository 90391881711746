import React, { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { makeStyles } from '@material-ui/core/styles';

import Tooltip from '@indot/tooltip';

const useStyles = makeStyles({
  table: {
    position: 'absolute',
    top: '64px',
    overflow: 'scroll',
    textAlign: 'center',
    padding: '20px',
  },
  tbody: {
    
  },
  header: {
    padding: '3px',
  },
  row: {
    '&:nth-child(even)': {
      backgroundColor: '#CCC',
    },
  },
  cell: {
    padding: '3px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '135px',
  },
});

const cols = [
    {
      accessor: 'route',
      Header: <Tooltip
        title="Interstate or State Route"
      >
        Route
      </Tooltip>,
    },
    {
      accessor: 'startmm',
      Header: <Tooltip
        title="Mile marker at the start of the route segment."
      >
        Start
      </Tooltip>,
    },
    {
      accessor: 'endmm',
      Header: <Tooltip
        title="Mile Marker at the end of the route segment."
      >
        End
      </Tooltip>,
    },
    {
      accessor: 'crashes',
      Header: <Tooltip
        title="Number of crashes of the selected type for the segment."
      >
        Crashes
      </Tooltip>,
      sortDescFirst: true,
    },
    {
      accessor: 'volume',
      Header: (
        <Tooltip
          title="Vehicle Miles Traveled, based on AADT."
        >
          VMT
        </Tooltip>
      ),
    },
    {
      accessor: 'crashRate',
      Header: (
        <Tooltip
          title="Crashes per 10^8 VMT."
        >
          Crash Rate
        </Tooltip>
      ),
      sortDescFirst: true,
    },
    {
      accessor: 'wzRate',
      Header: <Tooltip
        title="Crash Rate for the segment during the months it was a Workzone"
      >
        WZ Rate
      </Tooltip>,
      sortDescFirst: true,
    },
    {
      accessor: 'historicalRate',
      Header: <Tooltip
        title="Crash rate for the segment during the same months when it was not a Workzone."
      >
        Historical
      </Tooltip>,
      sortDescFirst: true,
    },
    {
      accessor: 'wzDelta',
      Header: <Tooltip
        title="Percent change from the Crash Rate to the Historical Rate"
      >
        WZ Δ
      </Tooltip>,
      sortDescFirst: true,
    },
    {
      accessor: 'contracts',
      Header: <Tooltip
        title="Contracts for the segment while it was a Workzone."
      >
        Contracts
      </Tooltip>,
    },
    {
      accessor: 'speed_limit',
      Header: <Tooltip
        title="Speed limit in MPH."
      >
        MPH
      </Tooltip>,
    },
    {
      accessor: 'district',
      Header: <Tooltip
        title="INDOT District"
      >
        District
      </Tooltip>,
    },
    {
      accessor: 'commuter_corridor',
      Header: <Tooltip
        title="If the route segment is flagged as a Commuter Corridor."
      >
        CC
      </Tooltip>,
    },
    {
      accessor: 'id',
      Header: <Tooltip
        title="Segment ID. Mostly here for Troubleshooting."
      >
        ID
      </Tooltip>,
    },
    {
      accessor: 'days',
      Header: <Tooltip
        title="Number of days in the selected time period. Note that records are binned by month."
      >
        Days
      </Tooltip>,
    },
];

export default ({ segmentData, crashData, districts, routes }) => {
  const classes = useStyles();
  const columns = useMemo(() => cols, []);
  const data = useMemo(() => crashData.segments.map(({
    id,
    crashes,
    volume,
    startmm,
    endmm,
    district_id,
    route_id,
    crashRate,
    wzRate,
    historicalRate,
    wzDelta,
    contracts,
    days,
  }) => {
    const {
      speed_limit,
      commuter_corridor,
    } = (segmentData[id] || {
      speed_limit: 'N/A',
      commuter_corridor: 'N/A',
    });

    return {
      route: (routes.find(x => x.value === route_id) || { label: 'N/A' }).label,
      startmm: Number(startmm).toFixed(1),
      endmm: Number(endmm).toFixed(1),
      crashes,
      volume,
      crashRate,
      wzRate,
      historicalRate,
      wzDelta: wzDelta === 'N/A' ? wzDelta : `${wzDelta}%`,
      contracts,
      speed_limit,
      district: (districts.find(x => x.value === district_id) || { label: 'N/A' }).label,
      commuter_corridor: String(commuter_corridor),
      id,
      days,
    };
  }), [crashData, segmentData, districts, routes]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  }, useSortBy);

  return (
    <table {...getTableProps()} className={classes.table}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={classes.header}
              >
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className={classes.tbody}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={classes.row}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()} className={classes.cell}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
};
