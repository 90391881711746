/**
 * ctx.js
 *
 * @description Provides the global context data for the Crashmap application.
 * @author jarsmith@indot.in.gov
 * @license MIT
 * @copyright INDOT, 2020
 */

import React from 'react';
import PropTypes from 'prop-types';

import LuxonUtils from '@date-io/luxon';
import theme from '@indot/material-theme';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { UserContextProvider } from '@indot/usetmcuser';

import { PreferenceCtxProvider } from '../hooks/usePreferences';

// import { TicketTypesProvider } from '../hooks/useTicketTypeList';
// import { TicketStatusesProvider } from '../hooks/useTicketStatusList';
// import { UserListProvider } from '../hooks/useUserList';
// import { UserContextProvider } from '../hooks/useUser';



/**
 * @description Provides global context data.
 *
 * @param {Object} [props] The destructured props object.
 * @param {React.ReactNode} props.children The React children to render.
 * @returns {React.Component} The Provider component.
 */
export const ContextProvider = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <UserContextProvider>
        <PreferenceCtxProvider>
          {children}
        </PreferenceCtxProvider>
      </UserContextProvider>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>
);

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContextProvider;
