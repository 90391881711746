import React, {
  useCallback,
  useEffect,
  // useRef,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import createDataStore from '@indot/react-ctx-store';
import useHistorical from '@indot/historical';
import { constructQs } from '@indot/qs';

const [useStateHook, CtxProvider] = createDataStore();

const QSCtxProvider = ({ children, initalData = {} }) => {
  const historical = useHistorical(useLocation(), useHistory());
  const initialState = { ...initalData, ...historical.searchParams };
  console.log('INITIAL STATE');
  console.log(initalData.zoom);
  console.log(historical.searchParams.zoom);
  console.log(JSON.stringify(initialState));
  const reducer = useCallback((s, a) => ({ ...s, ...a }), []);
  return (
    <CtxProvider initialState={initialState} reducer={reducer}>
      {children}
    </CtxProvider>
  );
};

const useQueryStringState = () => {
  const [state, dispatch] = useStateHook();
  const historical = useHistorical(useLocation(), useHistory());
  const currentQs = constructQs(state);
  useEffect(() => {
    const putativeURL = `${historical.currentPath}?${currentQs}`;
    if (putativeURL !== historical.currentRoute) {
      historical.updateURL(putativeURL);
    }
  });

  const setter = useCallback((update) => {
    dispatch(update);
  }, [dispatch]);

  return [state, setter, currentQs];
};

const useQueryString = () => useStateHook()[0];

export {
  QSCtxProvider,
  useQueryStringState,
  useQueryString,
};

export default useQueryStringState;

// const useOld = (initalData) => { // if qs is empty, otherwise discarded
//   // const zoomWentTo10 = useRef(false);
//   const historical = useHistorical(useLocation(), useHistory());
//   // console.log(`FROM historical searchParams: ${historical.searchParams.zoom}`);
//   const [state, setState] = useState({ ...initalData, ...historical.searchParams });
//   // console.log('STATE zoom: ' + state.zoom);
//   // if (state.zoom === 10)
//   //   zoomWentTo10.current = true;
//   const currentQs = constructQs(state);
//   useEffect(() => {
//     const putativeURL = `${historical.currentPath}?${currentQs}`;
//     if (putativeURL !== historical.currentRoute) {
//       // console.log(`Updating url with zoom ${currentQs.match(/zoom=\d+/)[0]}`);
//       historical.updateURL(putativeURL);
//     }
//   });

//   const setter = useCallback((update) => {
//     // console.log('useQueryString setter called with ' + JSON.stringify(update));
//     // if (zoomWentTo10.current && update.zoom === 11) {
//     //   throw new Error('Oops');
//     // }
//     if (typeof update === 'function') {
//       setState(update);
//     } else {
//       setState((_prev) => update);
//     }
//   }, []);
  
//   return [state, setter, currentQs];
// };
