import React, { useCallback } from "react";
import createStore from '@indot/react-ctx-store';

const DEFAULT_PREFERENCES = {
  coloration: 'gradient',
  sortBy: 'wzDelta',
  defaultCenter: [39.8, -86.16], // Indy
  defaultZoom: 11, // Indy
};

const [usePreferenceState, CtxProvider] = createStore();
const PreferenceCtxProvider = ({ children }) => {
  console.log('RENDERING CTX PROV');
  let cached = {};
  try {
    cached = JSON.parse(localStorage.getItem('/user/preferences'));
  } catch (err) {
    // NO-OP
  }

  const initial = Object.assign({}, DEFAULT_PREFERENCES, cached);
  const reducer = useCallback((state, update) => {
    const newState = { ...state, ...update };
    localStorage.setItem('/user/preferences', JSON.stringify(newState));
    return newState;
  }, []);

  return (
    <CtxProvider initialState={initial} reducer={reducer}>
      {children}
    </CtxProvider>
  );
};

const usePreferences = () => usePreferenceState()[0];
export {
  usePreferences,
  usePreferenceState,
  PreferenceCtxProvider,
};

export default usePreferences;

// export default () => {
//   const [state, setState] = useLocalState('/user/preferences', DEFAULT_PREFERENCES);
//   const [latitude, longitude] = state.defaultCenter;
//   console.log(`Sending ${latitude} ${longitude} to caller`);
//   // console.error(new Error('argh'));
//   return [state, setState];
//   // return useLocalState('/user/preferences', DEFAULT_PREFERENCES);
  
//   // Once login/server pref storage impl
//   // const [preferences, setPreferences] = useComposedState(
//   //   () => useLocalState('/user/preferences', DEFAULT_PREFERENCES),
//   //   () => usePartialState(...useUser())('preferences'),
//   // );
// };
