/**
 * LoginRoute.jsx
 *
 * @description Route for the login page.
 *
 * @author jarsmith@indot.in.gov
 * @license MIT
 * @copyright INDOT, 2019
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';

import Login from '@indot/login';
import ContentCard from '@indot/pagecontent';

import { useUser, useLogin } from '@indot/usetmcuser';

import CrashmapHeader from '../CrashmapHeader';

const fakeUser = JSON.stringify({
  user_name: 'jsmith',
  user_id: 1,
  token: 'ABCA1234-5678-FACE',
});

const fakeResponseFactory = () => new Response(fakeUser, { status: 200 });
const fakeFetch = (...args) => fakeResponseFactory(); 

/**
 * @description The login page component.
 * @param {Object} [props] The destructured props object.
 * @param {Object} props.location Current location from the history API.
 * @param {Function} props.notify Notifies the user of the given message via snackbar.
 * @returns {React.Component} The route component.
 *
 */
export const LoginRoute = ({
  notify,
}) => {
  const location = useLocation();
  const user = useUser();
  const [login] = useLogin(fakeFetch);
  return user?.token?.match(/^[\w.-]+$/)
    ? <Redirect to={{ pathname: '/', state: { from: location } }} />
    : (
      <React.Fragment>
        <CrashmapHeader />
        <ContentCard title="Login">
          <Login login={login} />
        </ContentCard>
      </React.Fragment>
    );
};

LoginRoute.propTypes = {
  notify: PropTypes.func.isRequired,
};

export default LoginRoute;
