/**
 * HomeRoute.jsx
 *
 * @description Route for the main page for the crash statistics map.
 *
 * @author jarsmith@indot.in.gov
 * @license MIT
 * @copyright INDOT, 2020
 */

import React, {
  useEffect,
  useCallback,
  useState
} from 'react';

import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Settings from '@material-ui/icons/Settings';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { useResponsiveDrawer } from '@indot/drawer';
import { useUser } from '@indot/usetmcuser';
import {
  useRemoteState,
  useLocalState,
  useComposedState,
// } from '@indot/state-hooks';
} from '../../hooks/state-hooks/index';

import createURL from '../../create_backend_url';

import Alert from '@indot/alert';
import CrashmapHeader from '../CrashmapHeader';
import Controls from '../Controls';
import CrashMap from '../Crashmap';
import CrashTable from '../Crashtable';

import { useQueryStringState } from '../../hooks/usequerystringstate';

import {
  DEFAULT_ROUTES,
  DEFAULT_DISTRICTS,
  DRAWER_WIDTH,
  MAX_DATA_AGE_EPSILON,
} from '../../constants';

const DISTRICT_URL = createURL('/districts');
const ROUTES_URL = createURL('/routes');
const SEGMENTS_URL = createURL('/segments');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
  },
  mainContent: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -DRAWER_WIDTH,
  },
  mainContentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  answersLinkContainer: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    padding: theme.spacing(3),
    fontSize: '1.2rem',
  },
  answersLink: {
    textDecoration: 'none',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  // settings
  settings: {
    position: "relative",
    left: '160px',
  },
  appbar: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: `100% - ${DRAWER_WIDTH}`,
      left: DRAWER_WIDTH,
    },
  },
  chevron: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  }
}));

export default () => {
  const theme = useTheme();
  const classes = useStyles();
  const { token } = useUser();
  const [alertContents, setAlertContents] = useState();
  const {
    Drawer,
    Main,
    MenuButton,
    useResponsiveDrawerState,
  } = useResponsiveDrawer({
    width: DRAWER_WIDTH,
  });

  const [_drawerOpen, setDrawerOpen] = useResponsiveDrawerState();

  const [
    queryData, 
    setQueryData,
    // qs,
  ] = useQueryStringState();
  
  const [districts] = useRemoteState({ url: DISTRICT_URL, initialState: DEFAULT_DISTRICTS, onError: () => { } });
  const [routes] = useRemoteState({ url: ROUTES_URL, initialState: DEFAULT_ROUTES, onError: () => {} });

  // Too big to hard-code defaults like we did with districts and routes, but still want to try to
  // cache local. TODO: only fetch if cache empty?
  // Has form { [interchange_id]: { route_id, rp, end_rp, speed_limit, district_id, commuter_corridor, path }}
  // const [segmentData] = useComposedState(
  //   () => useLocalState(SEGMENTS_URL, {}),
  //   () => useRemoteState({ url: SEGMENTS_URL, initialState: {} }),
  // )();
  const [segmentData] = useRemoteState({ url: SEGMENTS_URL, initialState: {} });

  // Query results, has form:
  // {
  //   segments: [
  //     {
  //       id,
  //       crashes, 
  //       volume,
  //       days,
  //       startmm,
  //       endmm,
  //       district_id,
  //       route_id,
  //       crashRate,
  //       wzRate,
  //       historicalRate,
  //       wzDelta,
  //       contracts
  //     },
  //   ],
  //   rollup: {
  //     crashes,
  //     rate,
  //     wzRate,
  //     historicalRate,
  //     wzDelta,
  //   },
  // }
  const [crashData, setCrashData] = useState({ segments: [], rollup: {} });
  const [shouldFetch, setShouldFetch] = useState(true);
  const dataURL = createURL('/results');
  const getData = () => {
    setShouldFetch(true);
  };

  const toggleDrawer = useCallback(() => {
    setDrawerOpen(prev => !prev);
  }, [setDrawerOpen]);

  const updateCenter = useCallback((evt) => {
    const {
      lat: latitude,
      lng: longitude,
    } = evt.target.getCenter();

    setQueryData({
      latitude,
      longitude,
    });
  }, [setQueryData]);

  const updateZoom = useCallback((evt) => {
    const zoom = evt.target.getZoom();
    // console.log(`updating zoom to ${zoom}`);
    setQueryData({
      zoom,
    });
  }, [setQueryData]);

  // TODO replace with useFetch?
  useEffect(() => {
    const fn = async () => {
      setShouldFetch(false);
      try {
        const {
          crashType,
          district,
          route,
          startDate,
          endDate,
          months,
        } = queryData;
        
        const body = JSON.stringify({
          crashType,
          district,
          route,
          startDate,
          endDate,
          months: months.map(Number),
          token,
        });

        const resp = await fetch(dataURL, { method: 'POST', body });
        if (resp.status < 200 || resp.state >= 400) {
          throw new Error(`HTTP ${resp.status} response, ${resp.statusText}`);
        }

        const data = await resp.json();
        try {
          localStorage.setItem(dataURL, JSON.stringify(data));
        } catch (err) {
          // no-op
        }

        setCrashData(data);
      } catch (err) {
        try {
          const dataString = localStorage.getItem(dataURL);
          if (dataString) {
            const data = JSON.parse(dataString);
            if (data) {
              const { dataLastUpdated } = data;
              const dataAge = dataLastUpdated && new Date(dataLastUpdated);
              if (Date.now - dataAge.getTime() > MAX_DATA_AGE_EPSILON) {
                setAlertContents(
                  <div>
                    We're having some technical difficulties communicating with
                    the TMC servers. The data you are looking at is from the cache,
                    and was fetched {dataAge.toString()}.
                  </div>
                );
              }
              setCrashData(data);
            }
          }
        } catch (err) {
          // TODO: change 'contact staff' to submit trouble ticket with a link.
          setAlertContents(
            <div>
              Sorry, we're having a bit of trouble connecting to the TMC servers
              right now. Please refresh the page and try again. If the problem
              persists please contact the TMC IT staff.
            </div>
          );
        }
      }
    };

    if (shouldFetch) fn();
  }, [queryData, setQueryData, dataURL, shouldFetch, token]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Alert>{alertContents}</Alert>
      <Main>
        <CrashmapHeader MenuButton={MenuButton} className={classes.appbar} />
        {
          queryData.view === 'map'
            ? <CrashMap
              routes={routes}
              crashData={crashData}
              segmentData={segmentData}
              onMoveEnd={updateCenter}
              position={[queryData.latitude, queryData.longitude]}
              onZoomStart={updateZoom}
              zoom={queryData.zoom}
            />
            : <CrashTable
              routes={routes}
              districts={districts}
              segmentData={segmentData}
              crashData={crashData}
            />
        }
      </Main>
      <Drawer>
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setDrawerOpen(false)}>
            <ChevronLeftIcon className={classes.chevron} />
          </IconButton>
          <Typography style={{ marginLeft: theme.spacing(2) }}>
            Controls
          </Typography>
          <Link to="/settings" className={classes.settings}>
            <IconButton>
              <Settings />
            </IconButton>
          </Link>
        </div>
        <Divider />
        <div className={classes.answersLinkContainer}>
          <Link to="/indotanswers" className={classes.answersLink}>
            <Button variant="text" color="primary">
              INDOT Answers...
            </Button>
          </Link>
        </div>
        <Controls
          data={queryData}
          setData={setQueryData}
          districts={districts}
          routes={routes}
          getData={getData}
        />
      </Drawer>
    </div>
  );
};
