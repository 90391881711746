/* eslint-disable no-sequences */
import React, {
  useMemo,
  useCallback,
} from 'react';

import { capFirstAll } from 'jsstring';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

import { pipe } from '@jasmith79/ts-utils';
import { extractSyntheticEventValue } from '@jasmith79/react-utils';

// import { usePartialState } from '@indot/state-hooks';
import { usePartialState } from '../../hooks/state-hooks/index';
import { useUser, useLogin } from '@indot/usetmcuser';
import { TMCAppbar } from '@indot/appbar';
import PageContent from '@indot/pagecontent';

import Tooltip from '@indot/tooltip';

import {
  APP_TITLE,
  GREEN,
  DEFAULT_DISTRICTS,
  DISTRICT_ZOOMS,
} from '../../constants';

import { usePreferenceState } from '../../hooks/usePreferences';
import { useFullRainbow, useBadRainbow } from '../../hooks/useRainbow';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '80%',
    minWidth: '200px',
    maxWidth: '400px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  formCtrl: {
    width: '80%',
    minWidth: '150px',
    padding: theme.spacing(1),
    marginTop: '5px',
  },
  fieldset: {
    borderRadius: '8px',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  colorSwatch: {
    width: '100px',
    height: '5px',
    display: 'inline-block',
  },
  colorSegment: {
    width: '1px',
    height: '5px',
    display: 'inline-block',
  },
  swathContainer: {
    position: 'absolute',
    right: '15px',
    display: 'inline-block',
  }
}));

const RANGE_100 = [];
for (let i = 0; i < 100; ++i) {
  RANGE_100.push(i);
}

export default () => {
  const [, logoff] = useLogin();
  const user = useUser();
  const classes = useStyles();
  const [preferences, setPreferences] = usePreferenceState();
  const useCursor = usePartialState(preferences, setPreferences);
  // const [coloration, setColoration] = useCursor('coloration');
  // const [sortBy, setSortBy] = useCursor('sortBy');
  // const [defaultMapCenter, setDefaultMapCenter] = useCursor('defaultCenter');
  // const [defaultZoom, setDefaultZoom] = useCursor('defaultZoom');
  const fullRainbow = useFullRainbow(RANGE_100.length);
  const bottomThreeQuartilesRainbow = useBadRainbow(RANGE_100.length - 75);
  const bottomHalfRainbow = useBadRainbow(RANGE_100.length - 50);
  const gradientSwath = useMemo(() => {
    return (
      <div className={classes.colorSwatch}>
        {RANGE_100.map((i) => {
          const color = fullRainbow.getColor(i);
          return (
            <div
              className={classes.colorSegment}
              style={{ backgroundColor: color }}
              key={color}
            />
          )
        })}
      </div>
    );
  }, [classes.colorSegment, classes.colorSwatch, fullRainbow]);

  const bottomQuartileSwath = useMemo(() => {
    return (
      <div className={classes.colorSwatch}>
        {RANGE_100.map((i) => {
          const color = i < 75 ? GREEN : bottomThreeQuartilesRainbow.getColor(i - 75);
          return (
            <div
              className={classes.colorSegment}
              style={{ backgroundColor: color }}
              key={`${color}${i}`}
            />
          );
        })}
      </div>
    );
  }, [classes.colorSegment, classes.colorSwatch, bottomThreeQuartilesRainbow]);

  const bottomHalfSwath = useMemo(() => {
    return (
      <div className={classes.colorSwatch}>
        {RANGE_100.map((i) => {
          const color = i < 50 ? GREEN : bottomHalfRainbow.getColor(i - 50);
          return (
            <div
              className={classes.colorSegment}
              style={{ backgroundColor: color }}
              key={`${color}${i + 100}`}
            />
          );
        })}
      </div>
    );
  }, [classes.colorSegment, classes.colorSwatch, bottomHalfRainbow]);

  // const defaultMapCenterSelectValue = [...defaultMapCenter, defaultZoom].join(',');
  const defaultMapCenterSelectValue = [...preferences.defaultCenter, preferences.defaultZoom].join(',');
  console.log(defaultMapCenterSelectValue);
  const handleDefaultMapCenterSelect = useCallback((evt) => {
    const [lat, lng, zoom] = extractSyntheticEventValue(evt).split(',').map(Number);
    setPreferences({
      defaultCenter: [lat, lng],
      defaultZoom: zoom,
    });
    // setDefaultMapCenter([lat, lng]);
    // setDefaultZoom(zoom);
  // }, [setDefaultMapCenter, setDefaultZoom]);
  }, [setPreferences]);

  return (
    <React.Fragment>
      <TMCAppbar title={APP_TITLE} />
      <PageContent title="Settings">
        <form onSubmit={(e) => e.preventDefault()} className={classes.form}>
          <fieldset className={classes.fieldset}>
            <legend>Sorting:</legend>
            <FormControl className={classes.formCtrl}>
              <InputLabel id="crashmap_coloring_label">Coloration</InputLabel>
              <Select
                labelId="crashmap_coloring_label"
                // value={coloration}
                value={preferences.coloration}
                // onChange={pipe(extractSyntheticEventValue, setColoration)}
                onChange={pipe(extractSyntheticEventValue, (val) => setPreferences({ coloration: val}))}
                renderValue={(val) => {
                  switch (val) {
                    case 'gradient': return 'Gradient';
                    case 'bottom_half': return 'Top 50% Green';
                    case 'bottom_quartile': return 'Top 75% Green';
                    default: throw new Error('Unrecognized value for colorization input render.');
                  }
                }}
              >
                <MenuItem value="gradient">
                  <Tooltip title="Continuous gradient from green to red.">
                    <Box>
                      Gradient<div className={classes.swathContainer}>{gradientSwath}</div>
                    </Box>
                  </Tooltip>
                </MenuItem>
                <MenuItem value="bottom_half">
                  <Tooltip title="Bottom half (by sorting criteria) is a gradient from yellow to red.">
                    <Box>
                      Top 50% Green<div className={classes.swathContainer}>{bottomHalfSwath}</div>
                    </Box>
                  </Tooltip>
                </MenuItem>
                <MenuItem value="bottom_quartile">
                  <Tooltip title="Bottom quartile (by sorting criteria) is a gradient from yellow to red.">
                    <Box>
                      Top 75% Green<div className={classes.swathContainer}>{bottomQuartileSwath}</div>
                    </Box>
                  </Tooltip>
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formCtrl}>
              <InputLabel id="crashmap_default_sorting_metric">Sort By</InputLabel>
              <Select
                labelId="crashmap_default_sorting_metric"
                // value={sortBy}
                value={preferences.sortBy}
                // onChange={pipe(extractSyntheticEventValue, setSortBy)}
                onChange={pipe(extractSyntheticEventValue, val => setPreferences({ sortBy: val }))}
              >
                <MenuItem value="crashes">
                  Crashes
                </MenuItem>
                  <MenuItem value="crashRate">
                    Crash rate
                </MenuItem>
                  <MenuItem value="wzDelta">
                    Workzone Δ
                </MenuItem>
              </Select>
            </FormControl>
          </fieldset>
          <FormControl className={classes.formCtrl}>
            <InputLabel id="crashmap_default_map_center">Map View Defaults To</InputLabel>
            <Select
              labelId="crashmap_default_map_center"
              value={defaultMapCenterSelectValue}
              onChange={handleDefaultMapCenterSelect}
            >
              <MenuItem value="39.8,-86.16,11">
                Indianapolis
              </MenuItem>
              {
                DEFAULT_DISTRICTS.map(({
                  label,
                  value,
                  lat,
                  lon,
                }) => (
                  <MenuItem
                    key={`${lat},${lon},${DISTRICT_ZOOMS[value]}`}
                    value={`${lat},${lon},${DISTRICT_ZOOMS[value]}`}
                  >
                    {`${capFirstAll(label.toLowerCase())} District`}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </form>
      </PageContent>
    </React.Fragment>
  );
};