export const sorter = prop => (a, b, i) => {
  if (+a[prop] > +b[prop]) return 1;
  if (+a[prop] < +b[prop]) return -1;
  return 0;
};

export const partition = (pred, arr) => {
  return arr.reduce(([a, b], x) => {
    (pred(x) ? a : b).push(x);
    return [a, b];
  }, [[], []]);
};
