import React, {
  useState,
  useEffect,
} from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles'; 

import PageContent from '@indot/pagecontent';
import { TMCAppbar } from '@indot/appbar';
// import { useRemoteState } from '@indot/state-hooks';
import {
  useRemoteState,
  useLocalState,
  useComposedState,
} from '../../hooks/state-hooks/index';

import createURL from '../../create_backend_url';

import Alert from '@indot/alert';

import {
  CONSTRUCTION_MONTH_NAMES as ANSWERS_MONTHS,
  MAX_DATA_AGE_EPSILON,
} from '../../constants';

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: theme.spacing(1),
  },
  table: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  headerText: {
    width: '80%',
    borderBottom: `1px solid #000`,
    display: 'inline-block',
  },
}));

export default () => {
  const url = createURL('/indotanswers');
  const [errorFetching, setErrorState] = useState(false); 
  const [alertContents, setAlertContents] = useState();
  const useWrappedRemote = () => useRemoteState({
    url,
    initialState: {},
    onError: (err) => {
      console.error(err);
      setErrorState(true);
    },
  });
  const useWrappedLocal = () => useLocalState(url, {});
  // const [answers] = useComposedState(useWrappedLocal, useWrappedRemote)();
  const [answers] = useRemoteState({
    url,
    initialState: {},
    onError: (err) => {
      console.error(err);
      setErrorState(true);
    },
  });
  const classes = useStyles();
  const { dataLastUpdated } = answers;
  const dataAge = dataLastUpdated && new Date(dataLastUpdated);
  const content = dataAge
    ? (
      <table className={classes.table}>
        <tbody>
          <tr>
            <th className={classes.cell}>
              <div className={classes.headerText}>
                Month
              </div>
            </th>
            <th className={classes.cell}>
              <div className={classes.headerText}>
                Delta
              </div>
            </th>
            <th className={classes.cell}>
              <div className={classes.headerText}>
                YTD
              </div>
            </th>
          </tr>
          {
            ANSWERS_MONTHS
              .filter(mon => mon in answers)
              .map((mon) => {
                const { ytd, month: delta } = answers[mon];
                return (
                  <tr>
                    <td className={classes.cell}>{mon}</td>
                    <td className={classes.cell}>{delta}%</td>
                    <td className={classes.cell}>{ytd}%</td>
                  </tr>
                );
              })
          }
        </tbody>
      </table>
    )
    : errorFetching
      ? (
        <div>
          Sorry, we're having a bit of trouble connecting to the TMC servers
          right now. Please refresh the page and try again. If the problem
          persists please contact the TMC IT staff.
        </div>
      )
      : <CircularProgress color="secondary" />;

  useEffect(() => {
    if (
      dataAge
      && !alertContents
      && (Date.now() - dataAge.getTime()) > MAX_DATA_AGE_EPSILON
    ) {
      setAlertContents(
        <div>
          We're having some technical difficulties communicating with
          the TMC servers.
          The data you are looking at is from the cache, and was
          fetched {dataAge.toString()}.
        </div>
      );
    }
  }, [dataAge, alertContents, setAlertContents]);
  
  return (
    <React.Fragment>
      <Alert>{alertContents}</Alert>
      <TMCAppbar title="INDOT Answers Workzone-Safety KPI" />
      <PageContent title="Workzone Δ">
        {content}
      </PageContent>
    </React.Fragment>
  );
};

