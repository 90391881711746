import Rainbow from '@indot/rainbowvis';
import { RED, YELLOW, GREEN, ORANGE } from '../constants';

const BAD_COLORS = [
  YELLOW,
  ORANGE,
  RED,
];

export const useBadRainbow = (n) => new Rainbow({
  min: 0,
  max: n - 1,
  colours: BAD_COLORS,
});

export const useFullRainbow = (n) => new Rainbow({
  min: 0,
  max: n - 1,
  colours: [GREEN, ...BAD_COLORS],
});
