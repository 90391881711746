import React, { useMemo } from 'react';

import { useTheme } from '@material-ui/core/styles';

// import Account from '@indot/account';
// import Account from './Account';
import { AppbarBase, AppbarTitle } from '@indot/appbar';
import { INDOTLogo } from '@indot/logos';
// import {
//   CrapBar,
//   // TMCAppbar
// } from './Appbar';
import {
  useUser,
  // useLogin
} from '@indot/usetmcuser';

import { APP_TITLE } from '../constants';

export const CrashmapHeader = ({
  MenuButton,
  className,
}) => {
  const user = useUser();
  const theme = useTheme();

  const LoggedInComponent = useMemo(() => {
    return () => (
      <AppbarBase className={className}>
        {
          window.matchMedia(`(min-width: ${theme.breakpoints.values.lg}px)`).matches
            ? <INDOTLogo />
            : <MenuButton />
        }
        <AppbarTitle title="INDOT Crash Statistics" />
      </AppbarBase>
    );
  }, []);
  // const [, logout] = useLogin();
  return user.token
    ? <LoggedInComponent />
    : <LoggedInComponent />
};

export default CrashmapHeader;
