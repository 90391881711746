/* eslint-disable no-sequences */
import React, { useCallback } from 'react';
import { emptyFn } from '@jasmith79/ts-utils';
import { TMCMap, TMCPoly } from '@indot/tmcmap';
// import { TMCMap, TMCPoly } from './mappy/index';

import { makeStyles } from '@material-ui/core/styles';

import { GREEN } from '../constants';
import { partition, sorter } from '../utils';

import usePreferences from '../hooks/usePreferences';
import { useBadRainbow, useFullRainbow } from '../hooks/useRainbow';

const useStyles = makeStyles({
  map: {
    width: '100%',
    height: 'calc(100% - 64px)',
    top: '64px',
  },
});

export default ({
  crashData,
  segmentData,
  routes,
  position,
  zoom,
  onMoveEnd = emptyFn,
  onZoomStart = emptyFn,
}) => {
  // console.log('Rendering CRASHMAP.');
  const classes = useStyles();
  const {
    coloration,
    sortBy,
    // defaultCenter: [lat, lng],
  } = usePreferences();
  // console.log(`From prefs: ${lat} ${lng}`);
  // const [latitude, longitude] = position;
  // console.log(`From position ${latitude} ${longitude}`);
  const shouldColor = useCallback((x) => {
    switch (sortBy) {
      case 'wzDelta': return x.wzDelta !== 'N/A';
      case 'crashes':
      case 'crashRate':
        return x[sortBy] !== 0;
      default: throw new Error(`Unknown sortBy ${sortBy}.`);
    }
  }, [sortBy]);

  const [colored, unColored] = partition(
    shouldColor,
    crashData
      .segments
      .filter(({ id }) => (id in segmentData)),
  );

  const length = coloration === 'gradient'
    ? colored.length
    : coloration === 'bottom_half'
      ? Math.round(colored.length / 2) + 1
      : Math.round(colored.length / 4) + 1;

  const fullRainbow = useFullRainbow(length);
  const badRainbow = useBadRainbow(length);

  const colorize = (i) => {
    switch (coloration) {
      case 'gradient': return fullRainbow.getColor(i);
      case 'bottom_half':
      case 'bottom_quartile':
        return i < length ? GREEN : badRainbow.getColor(i - length);
      default: throw new Error(`Unknown colorization option ${coloration} rendering segments`);
    }
  };

  const data = [
    ...colored
      .sort(sorter(sortBy))
      // 
      .map((x, i) => (x.color = colorize(i), x)),
    ...unColored.map((x) => (x.color = GREEN, x)),
  ];

  return data.length
    ? (
      <TMCMap
        onMoveEnd={onMoveEnd}
        position={position}
        onZoomStart={onZoomStart}
        initZoom={zoom}
        className={classes.map}
      >
        {data
          // .map(() => '')
          .map((segmentCrashRecord, i) => {
            const {
              id,
              crashes,
              volume,
              days,
              startmm,
              endmm,
              // district_id,
              route_id,
              crashRate,
              wzRate,
              historicalRate,
              wzDelta,
              contracts,
              color,
            } = segmentCrashRecord;
            // console.log(wzDelta);

            const {
              // May want to show these later?
              // speed_limit,
              // commuter_corridor,
              path,
            } = segmentData[id];

            // back-calculated AADT, should jive fairly close to the official one.
            const vol = volume;
            const vmt = days * (+endmm - +startmm);
            const aadt = vol && vmt
              ? Math.floor(vol / vmt)
              : 0;

            const routeRecord = routes.find((rt) => rt.value.toString() === route_id.toString());
            const route = routeRecord?.label || 'Unknown';
            // const district = districts.find((dst) => dst.value.toString() === district_id.toString());

            return (
              <TMCPoly path={path} key={id} color={color}>
                <table>
                  <tbody>
                    <tr>
                      <td>Segment</td>
                      <td>{id}</td>
                    </tr>
                    <tr>
                      <td>Route</td>
                      <td>{route}</td>
                    </tr>
                    <tr>
                      <td>Start MM</td>
                      <td>{startmm}</td>
                    </tr>
                    <tr>
                      <td>End MM</td>
                      <td>{endmm}</td>
                    </tr>
                    <tr>
                      <td>Crashes</td>
                      <td>{crashes}</td>
                    </tr>
                    <tr>
                      <td>AADT*</td>
                      <td>{aadt}</td>
                    </tr>
                    <tr>
                      <td>Crash Rate</td>
                      <td>{crashRate}</td>
                    </tr>
                    <tr>
                      <td>Historical</td>
                      <td>{historicalRate}</td>
                    </tr>
                    <tr>
                      <td>Workzone Rate</td>
                      <td>{wzRate}</td>
                    </tr>
                    <tr>
                      <td>Delta</td>
                      <td>{wzDelta}</td>
                    </tr>
                    {
                      contracts
                        ? (
                          <tr>
                            <td>Contracts</td>
                            <td>{contracts}</td>
                          </tr>
                        )
                        : null
                    }
                  </tbody>
                </table>
              </TMCPoly>
            );
          })
        }
      </TMCMap>
    )
    : (
      <TMCMap
        onMoveEnd={onMoveEnd}
        position={position}
        onZoomStart={onZoomStart}
        initZoom={zoom}
        className={classes.map}
      />
    );
};
